//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {
    BaseView: () => import("@/components/BaseView"),
    item: () => import("./attendance-info"),
  },
  data() {
    return {
      childLoading: false,
      fatData: {}, // 需要传给日历组件
    };
  },
  created() {
    this.sendParams();
  },
  methods: {
    sendParams() {
      this.fatData = {};
      this.fatData.menuType = "privateAttendance"; // 个人考勤
      this.fatData.tabsNum = "1";
      this.fatData.fatTimeYMD = new Date()
        .toLocaleDateString()
        .timeFormat("yyyy-MM-dd");
      this.fatData.fatUsId = undefined;
    },
    //更新
    updata(val) {},
  },
};
